/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "technology-to-support-clear-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#technology-to-support-clear-hearing",
    "aria-label": "technology to support clear hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Technology to Support Clear Hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with hearing loss have more difficulty understanding speech in noisy environments. The auditory system can no longer filter out noise as well. Patients also report that noisy places annoy and distract them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rexton Sterling ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing devices"), " contain several technologies that help with speech understanding and comfort in noisy places. First, an automatic classifier in the computer chip identifies the kind of environment you are currently experiencing. The hearing aids then switch to the appropriate listening mode for the environment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Each listening mode controls the directional microphones of the hearing aids for optimal clarity. Directional microphones pick up sounds coming from the direction of the voices you want to hear and reduce background noise. Directional microphone operation is guided by the Voice Ranger technology to accurately identify the speaker."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Reverb Reducer technology increases the ease and comfort of listening in settings with many hard surfaces. When there are hard floors and furniture, such as in shopping malls, you may hear a lot of echoes. This technology reduces the echoes."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have severe to profound hearing loss in higher frequencies, your hearing care professional may opt to enable the bandwidth compression algorithm. This system shifts higher pitched sounds down to frequency ranges where you have better hearing. You may hear more clearly with this feature."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "customized-expert-fitting-by-professionals",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customized-expert-fitting-by-professionals",
    "aria-label": "customized expert fitting by professionals permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customized, Expert Fitting by Professionals"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sterling hearing aids are custom made to fit your ears from an impression that your hearing care professional takes during your consultation appointment. These aids may be made in several sizes: IIC (invisible in the canal), ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/mini-ix/",
    className: "c-md-a"
  }, "CIC (completely in the canal)"), ", ITE (in the ear), and ITC (in the canal). Different sizes offer benefits for people concerned about dexterity, vision problems, and discreetness. ", React.createElement(_components.a, {
    href: "/about-us/network/",
    className: "c-md-a"
  }, "Your hearing care professional"), " will work with you to determine the kind that fits your needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As part of your fitting appointment, your hearing care professional will run a feedback test. The feedback manager in your hearing aids will use this measurement to reduce the likelihood that the hearing aids will squeal while in your ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/rexton/",
    className: "c-md-a"
  }, "Rexton"), " offers ", React.createElement(_components.a, {
    href: "/hearing-aids/accessories/",
    className: "c-md-a"
  }, "several accessories"), " to help you make the most of your investment in better hearing. One streamer device sends the sound of your favorite television programs directly into your hearing aids. Another device, a remote microphone, makes it easier for you to hear your partner in a noisy restaurant. Your hearing care professional will help you select and manage a complete technology solution."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Rexton Sterling now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "options-that-fit-your-precise-needs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#options-that-fit-your-precise-needs",
    "aria-label": "options that fit your precise needs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Options That Fit Your Precise Needs"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When wearing hearing aids, sometimes people report that their voice sounds different. If you cover your ears with your hands and talk, you will notice that your voice changes. This is called the “occlusion effect”. Because hearing aids fit into your ear, they may produce an occlusion effect. In addition, you will hear your voice amplified through your hearing aid microphones. Rexton’s My Voice technology works to make your voice sound more natural through your hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you like to listen to music, you want specialized processing in your hearing aids that allow you to hear your favorite song fully. Conventional hearing aid processing focuses on speech understanding and introduces artifacts into music. Rexton hearing aids offer a mode with an extended dynamic range for better music enjoyment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Rexton Smart Remote App for smart phones allows you to adjust the volume, program, and tone of your hearing aids to your likely. If you prefer not to use a smart phone, ask about a discreet remote control."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some patients have ringing in the ears, also known as tinnitus. Hearing aids can help by masking the sound of tinnitus, so it is less bothersome. If you have tinnitus, ask your provider about adding the Resound tinnitus management feature to your hearing aid programs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rarely, someone may have severe hearing loss just in one ear. This can cause problems hearing when listening to friends talking towards the bad ear. Your hearing care provider can discuss whether the Rexton CROS option would work well for you. ", React.createElement(_components.a, {
    href: "/hearing-aids/types/cros-bicros/",
    className: "c-md-a"
  }, "CROS is a special hearing aid technology"), " that routes sound from a bad ear into a good ear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "sign-up-today-for-expert-guidance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sign-up-today-for-expert-guidance",
    "aria-label": "sign up today for expert guidance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sign up Today for Expert Guidance"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sign up for a risk-free trial today at hear.com and one of our hearing experts will call you to discuss your unique needs. After answering all your initial questions about the Rexton Sterling hearing aids, the expert will set up an appointment for you with a local hearing care provider for a test and a consultation. Once you and the provider have selected and fitted the right technology for your hearing loss, you will have 45 days to try out your new hearing aids risk-free. If you are not satisfied, simply return them for a full refund."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our expert will work with you every step of the way on your better hearing journey. If you need financial assistance for hearing aids, we can match you with the right program for you."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Rexton Sterling now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
